
// Dropdown arrow icon
.form-dropdown-icon {
  background-image: url('../../../../_new/icons/angle-down-solid.svg');
  background-repeat: no-repeat;
  background-position: right 0.5rem center; 
  background-size: 12px; 
  padding-right: 2.5rem; 
  appearance: none; 
  -webkit-appearance: none;
  -moz-appearance: none;
}